.checklist {
  display: flex;
  margin: auto;
  text-align: center;
  justify-content: center;
  align-items: center;
}


.card-checklist {
  display: flex;
  flex-direction: column;
  background: white;
  border-radius: 14px;
  width: 100%;
  max-width: 450px;
  min-width: 300px;
  padding: 3%;
  box-shadow: 0px 3px 14px #afafaf;
  border-bottom: 8px solid #054922;
  text-align: justify;
}

.card-checklist h2 {
  font-weight: bold;
  color: black;
  margin: 0;
  padding: 3%;
  font-size: 18px;
}



.checklist-item {
  display: flex;
  justify-content: space-between;
  cursor: pointer;
  padding: 4%;
  margin: 2%;
  color: #3d3d3d;
  background: rgb(255 255 255);
  border-radius: 6px;
  box-shadow: 4px 5px 9px #afafaf;
  transition: transform 0.3s ease;
}

.checklist-text {
  flex-grow: 1;
}

.checklist-item:hover {
  background-color: #f2f2f2;
}

.checked {
  color: black;
  border-left: 4px solid green;
}

.checklistNext {
  padding: 4%;
  margin-top: 1%;
  background: #054922;
  color: white;
  border: none;
  border-radius: 6px;
  cursor: pointer;
}

.checkicon {
  color: green;
}

.checklistNext:hover {
  background-color: #1abd80fc;
}



.btn-checklist {
  text-align: center;
}