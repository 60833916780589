@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap');

.modulo-card {
  background: #f0f0f0;
  border-radius: 8px;
  width: 300px;
  max-height: 300px;
  margin: 20px;
  box-shadow: 1px 8px 6px rgba(0, 0, 0, 0.1);
  transition: transform 0.2s;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow: hidden;
  font-family: 'Roboto', Consolas, Monaco, monospace;
}

.modulo-card:hover {
  transform: scale(1.05);
}

.modulo-info {
  
  padding: 10px;
  text-align: left;
  flex-grow: 1;
}

.visualizar-btn {
  font-family: 'Roboto', Consolas, Monaco, monospace; 
}

a.visualizar-btn {
  text-align: center;
  width: 94%;
}

.modulo-info p {
  display: flex;
  align-items: center;
  color: #383838;
}

img.icon {
  margin: 0 2% 0 1%;
}


