html,
body {
  overflow: hidden !important;
  }


.container-cp {
    display: flex;
    flex-direction: row-reverse;
    height: 100%;
    width: 100%;
    justify-content: center;
}