
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap');

body,
html {
    margin: 0;
    padding: 0;


}

.container-select {
    background-image: url('../../assets/ícones_favicon\ maga.svg');
    background-color: rgb(253 253 253);
    background-repeat: no-repeat;
    background-position: center center;
    height: 100vh;
}

.bodySelect {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-image: url('../../assets/ícones_elemento\ 2.svg'),
        url('../../assets/ícones_elemento\ 1.svg');
    background-repeat: no-repeat;
    background-position: top right,
        bottom left,
        top right,
        bottom left;
    background-size: 50px auto, 50px auto, 50px 50px, 50px 50px;
    background-size: 30% auto, 30% auto;

}


.container-select {
    color: #e62d47;
}

.select-box {
    width: 100%;
    padding: 10px;
    font-size: 16px;
    border: 1px solid #ccc;
    border-radius: 4px;
    outline: none;
}

.select-box:focus {
    border-color: #e9354d;
    box-shadow: 0 0 5px #fcfcfc
}


footer.footer-selectScreen {
    position: fixed;
    bottom: 1rem;
    text-align: center;
}

.footer-selectScreen img {
    width: 35%;
}