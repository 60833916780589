html,
body {
  height: 100%;
  margin: 0;
  padding: 0;
  overflow: hidden;
  display: flex;
  font-family: 'Roboto', sans-serif;
}



.footer img {
    width: 50%;
}