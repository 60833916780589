@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap');

.home {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  background-image: url('../../assets/ícones_favicon\ maga.svg');
  background-color: rgb(253, 253, 253);
  background-repeat: no-repeat;
  background-position: center center;
}

.container-body {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  background-image: url('../../assets/ícones_elemento\ 2.svg'),
    url('../../assets/ícones_elemento\ 1.svg');
  background-position: 100% 0, 0 100%, 100% 0, 0 100%;
  background-repeat: no-repeat;
  background-size: 50px auto, 50px auto, 50px 50px, 50px 50px;
  background-size: 20% auto, 20% auto;
  display: block;
  height: 100vh;
  overflow-x: auto;
  width: 100vw;
  align-content: space-evenly;
}

.container-body h1 {
  text-align: center;
  color: #e62d47;
  margin-bottom: 20px;
  font-size: 38px;
}

.modulos {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px;
  margin: 0 10%;
}

.admin-icon {
  cursor: pointer;
  color: #333;
  position: absolute;
  margin: 20px;
}

.admin-icon:hover {
  color: #555;
}

.footer-home {
  text-align: center;
  padding: 20px;
}

.footer-home img {
  width: 22%;
}