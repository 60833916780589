/* Unauthorized.css */
.unauthorized-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    text-align: center;
    background-color: #f9f9f9;
    color: #333;
    font-family: Arial, sans-serif;
}

.unauthorized-container h1 {
    font-size: 2em;
    margin-bottom: 10px;
}

.unauthorized-container p {
    font-size: 1.2em;
    margin-bottom: 20px;
}

.unauthorized-container .back-link {
    color: #007bff;
    text-decoration: none;
    font-size: 1em;
}

.unauthorized-container .back-link:hover {
    text-decoration: underline;
}
