.visualizar-btn {
    background: #e84e64;
    color: white;
    border: none;
    padding: 10px;
    width: 100%;
    border-radius: 0 0 8px 8px;
    cursor: pointer;
    font-size: 16px;
    transition: background 0.3s;
    text-decoration: none;
    display: flex;
    justify-content: center;
  }
  
  .visualizar-btn:hover {
    background: #e52c46;
  }
  