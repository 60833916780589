html {
    background-color: rgb(253, 253, 253);
    background-image: url('../../assets/fundo escamas.png');
    background-size: cover;
    background-attachment: fixed;
    height: 100%;
    overflow: hidden;
  }
  
  .container-cp iframe {
    width: 100%;
    height: auto; 
    border-radius: 14px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    max-width: 82%; 
    aspect-ratio: 16/9; 
}
  .video-player-container {
    display: flex;
    flex: 1;
    flex-direction: column;
    align-items: center;
    align-self: center;
    margin-bottom: 4vh;
}
  
  button.buttonNextVideo,
  button.buttonBackVideo {
    padding: 14px;
    border-radius: 8px;
    background: #32519f;
    color: white;
    border: none;
    cursor: pointer;
  }
  
  button.buttonBackVideo {
    background: #e52c46;
  }
  
  button.buttonNextVideo:hover,
  button.buttonBackVideo:hover {
    opacity: 0.95;
  }
  
  .video-buttons {
    display: flex;
    gap: 12px;
    margin-top: 1rem;
  }
  

