.icon-container {
    cursor: pointer;
    position: absolute;
    top: 2vh;
    left: 2vw;
    z-index: 2;

}

.icon {
    transition: transform 0.2s, opacity 0.2s;
  
}

.outlined {
    color: black;
    height: 40px;
    width: 40px;
}

.filled {
    color: #32519f;
    height: 40px;
    width: 40px;
    transform: scale(1.1);
}