.modulo-header {
    background-size: cover;
    color: white;
    padding: 10px;
    border-radius: 8px 8px 0 0;
    display: flex;
    justify-content: center;
    padding: 0;
    margin: 0;
  }
  

  .modulo-media img {
    max-width: 100%;
    height: auto;
  }
  