@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;600&display=swap');

#root {
  margin: 0;
  padding: 0;
}


html,
body {
  height: 100%;
  margin: 0;
  padding: 0;
  overflow: hidden;
  display: block;
  background-image: url(./assets/fundo\ escamas.png);
  font-family: 'Poppins', sans-serif; 
}
#info-div {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  z-index: 1000;
  font-size: 20px;
}

.inaccessible {
  pointer-events: none;
  opacity: 0;
}