

.quiz {
  display: flex;
  margin: auto;
  text-align: center;
  justify-content: center;
  align-items: center;
  flex: 1;
}

.quiz li.active {
  transform: scale(1.02);
  margin: 0;
  border-left: 4px solid #3a47ef;
}

.quiz li:hover {
  transform: scale(1.02);
}

.quiz h2 {
  font-size: 18px;
  color: black;
  margin-bottom: 1rem;
  text-align: justify;
}

.question-btn {
  text-align: center;
}

.correct {
  color: green;
  }

.wrong {
  color: red;
  }

.reason-correct {
  color: green;
}

.reason-incorrect {
  color: red;
}


.questionNext {
  padding: 10px 20px;
  margin-top: 1rem;
  background: #3a47ef;
  color: white;
  border: none;
  border-radius: 6px;
  cursor: pointer;
}

.card-question {
  display: flex;
  flex-direction: column;
  background: white;
  border-radius: 14px;
  width: auto;
  max-width: 800px;
  max-height: 40%;
  box-shadow: 0px 3px 14px #afafaf;
  padding: 3%;
  text-align: justify;
  margin: 2%;
}

.card-question li {
  cursor: pointer;
  padding: 10px;
  margin: 5px 0;
  color: #3d3d3d;
  background: #fff;
  border-radius: 6px;
  box-shadow: 4px 5px 9px #afafaf;
  transition: transform 0.3s ease;
}



