.progress-bar-container {
  position: relative;
  width: 100%;
  height: 20px;
  background-color: #f0f0f0;
  margin-bottom: 20px;
  border-radius: 10px;
}

.progress-bar {
  height: 100%;
  background-color: #007605;
  border-radius: 10px;
  box-shadow: 0 0 12px 1px #afafaf;
  position: relative;
}

.progress-text-container {
  position: absolute;
  top: -30px; 
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  font-weight: bold;
  text-align: center;
  z-index: 1; 
}

.progress-text {
  color: black; 
}

.completion-text {
  color: #4caf50; 
  font-size: 14px;
}
