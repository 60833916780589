html, body {
  margin: 0;
  padding: 0;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

.container-cp {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
  padding: 0;
  margin: 0;
}

.certificate-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: auto;
  flex: 1;
}

.certificate-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between; 
  width: 90%;
  max-width: 600px;
  padding: 20px;
  background-color: #ffffff;
  border-radius: 14px;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.1);
  box-sizing: border-box;
  height: auto; 
  min-height: 300px; 
  text-align: center;
}

.certificate-content img {
  width: 50%;
  max-width: 300px;
}

.certificate-content h2 {
  font-size: 24px;
  margin-bottom: 15px;
  color: #333333;
}

.certificate-content p {
  font-size: 18px;
  line-height: 1.6;
  color: #666666;
  margin-bottom: 20px; 
}

.button-home {
  background-color: #f4f4f4;
  color: #333333;
  border: none;
  padding: 12px 25px;
  font-size: 18px;
  cursor: pointer;
  border-radius: 4px;
  margin-top: auto; 
  width: 100%;
  max-width: 100%;
  box-sizing: border-box;
  transition: background-color 0.3s, transform 0.3s;
  border-bottom: 4px solid #bb3354;
}

.button-home:hover {
  transform: scale(1.02);
  background: #f8f4f4;
}
